import type { SectionProps, LinksButton } from "../../../types";
import {
  buttonGroup,
  type ButtonGroupVariantProps,
} from "./button-group.variants";

export type UseButtonGroupProps = SectionProps<
  {
    title?: string | null;
    buttons?: LinksButton[];
  },
  ButtonGroupVariantProps
>;

export const useButtonGroup = (props: UseButtonGroupProps) => {
  const {
    className = "",
    sectionName,
    theme = "dark",
    align = "left",
    spacing = "lg",
    data: { title, buttons = [] },
  } = props;

  const styles = buttonGroup({ theme, align, spacing });

  return {
    className,
    styles,
    theme,
    sectionName,
    data: {
      title,
      buttons,
    },
  };
};

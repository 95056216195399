import type { ModelsIconCard, SectionProps } from "../../../types";
import {
  iconCardVariant,
  type IconCardVariantProps,
} from "./icon-card.variants";

export type UseIconCardProps = SectionProps<
  ModelsIconCard,
  IconCardVariantProps
>;

export const useIconCard = (props: UseIconCardProps) => {
  const {
    sectionName,
    className = "",
    appearance = "ghost",
    theme = "dark",
    data,
  } = props;

  const hasList = data?.list && data.list.length > 0;

  const styles = iconCardVariant({
    theme,
    appearance,
    hasFooting: !!data.link,
  });

  return {
    sectionName,
    theme,
    className,
    styles,
    hasList,
    appearance,
    data,
  };
};

import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import type { SectionsHero } from "../../../types";
import { getMediaType } from "../../../util/media";
import { heroHighlightVariant } from "./hero-highlight.variants";

export interface UseHeroHighlightProps {
  align: SectionsHero["align"];
  icon: SectionsHero["icon"];
  imageHighlight: SectionsHero["imageHighlight"];
  baseClassName?: string;
  withSidebar?: boolean;
}

export const useHeroHighlight = (props: UseHeroHighlightProps) => {
  const {
    align,
    icon,
    imageHighlight,
    baseClassName = "",
    withSidebar = false,
  } = props;

  const [showHighlight, setShowHighlight] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 768) {
      !showHighlight && setShowHighlight(true);
    } else {
      showHighlight && setShowHighlight(false);
    }
  }, [showHighlight, width]);

  const styles = heroHighlightVariant({
    show: align === "left" || showHighlight,
    withSidebar,
  });

  const mediaType = imageHighlight && getMediaType(imageHighlight.url);

  return { styles, baseClassName, mediaType, icon, imageHighlight };
};

import { tv, type VariantProps } from "../../../util/tv";

export const newsListVariant = tv({
  slots: {
    base: [
      "relative",
      "pt-28 pb-14 md:pt-40 md:pb-24 3xl:py-48",
      "overflow-hidden",
      "after:pointer-events-none",
      'after:content-[""]',
      "after:absolute after:bottom-0 after:left-0",
      "after:w-full after:h-96",
      "after:bg-gradient-to-t",
      "after:from-[-120%]",
      "after:to-transparent after:to-[80%]",
    ],
    wrapper: ["container-fluid", "relative z-20"],
    heading: ["text-center", "mb-8"],
    preTitle: ["mb-3"],
    title: [],
    description: ["mt-5", "mx-auto"],
    categories: ["hide-scrollbar", "overflow-x-auto", "relative z-20"],
    list: [
      "inline-flex",
      "md:flex justify-center gap-2",
      "py-2",
      "px-8",
      "whitespace-nowrap",
      "snap-x snap-mandatory",
    ],
    content: [
      "container-fluid",
      "relative z-20",
      "pb-8",
      "mt-10 sm:mt-14",
      "bg-gray-800",
      "rounded-3xl",
    ],
    footing: [],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-black", "after:from-blue-400/40"],
        preTitle: "text-blue-400",
        description: "text-gray-60",
      },
      light: {
        base: ["bg-white", "after:from-blue-100/40"],
        preTitle: "text-blue-100",
        description: "text-gray-60",
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export const newsListGridVariant = tv({
  slots: {
    base: [],
    content: ["grid grid-cols-2 md:grid-cols-12 gap-y-12 sm:gap-10"],
    left: ["col-span-2 md:col-span-8"],
    right: ["col-span-2 md:col-span-4"],
    card: [""],
    footing: ["w-full", "flex items-center justify-center", "mt-20"],
  },
});

export const newsCardVariant = tv({
  slots: {
    base: ["flex gap-4 items-start"],
    figureWrapper: [],
    figure: [
      "rounded-3xl",
      "overflow-hidden",
      "focus-within:outline focus-within:outline-2",
    ],
    link: ["block border-b border-gray-600 last:border-none"],
    textWrapper: [],
    preTitle: ["hidden", "text-blue-400"],
    title: ["hover:text-blue-400"],
  },
  variants: {
    appearance: {
      horizontal: {
        base: ["flex-row-reverse gap-6 items-center", "py-6"],
        figureWrapper: ["basis-full", "max-w-[150px]"],
        textWrapper: ["flex flex-col gap-2 basis-full"],
        preTitle: ["flex"],
        title: ["text-gray-40"],
      },
      vertical: {
        base: ["flex-col"],
        title: ["text-gray-40 font-semibold"],
        figureWrapper: ["w-[200px] md:w-full"],
      },
    },
    highlight: {
      true: {
        base: ["mt-[-24px]"],
        preTitle: ["flex", "!text-display-16", "mb-4"],
        title: ["!text-display-24"],
        figureWrapper: ["w-full"],
      },
      false: {
        title: [
          "!text-display-16 leading-snug",
          "text-ellipsis overflow-hidden",
        ],
      },
    },
  },
});

export const newsListCategoryVariant = tv({
  slots: {
    base: ["container-fluid py-12 border-b border-gray-600 last:border-none"],
    content: ["grid grid-cols-1 md:grid-cols-12 gap-10 md:gap-6"],
  },
});

export type NewsListVariantProps = VariantProps<typeof newsListVariant>;

export type NewsListReturnVariantProps = ReturnType<typeof newsListVariant>;

export type NewsCardVariantProps = VariantProps<typeof newsCardVariant>;

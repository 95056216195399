import { tv, type VariantProps } from "../../../util/tv";

export const buttonGroup = tv({
  slots: {
    base: ["flex flex-col gap-4 sm:justify-start"],
    title: [],
    buttons: ["flex flex-row flex-wrap"],
  },
  variants: {
    theme: {
      dark: {
        title: "text-gray-40",
      },
      light: {
        title: "text-gray-60",
      },
    },
    spacing: {
      xs: {
        buttons: "gap-1",
      },
      sm: {
        buttons: "gap-2",
      },
      md: {
        buttons: "gap-3",
      },
      lg: {
        buttons: "gap-6",
      },
      xl: {
        buttons: "gap-8",
      },
    },
    align: {
      left: {
        title: "text-left",
        buttons: "justify-start rtl:ml-auto",
      },
      center: {
        title: "text-center",
        buttons: "justify-center",
      },
      center_left: {
        title: "text-center md:text-left justify-center md:justify-start",
        buttons: "justify-center md:justify-start",
      },
    },
  },
  defaultVariants: {
    theme: "dark",
    align: "left",
    spacing: "lg",
  },
});

export type ButtonGroupVariantProps = VariantProps<typeof buttonGroup>;

import NextImage from "next/image";
import NextLink from "next/link";
import { ModelsNewsCard } from "../../../types";
import { Text } from "../../ui/text";
import { newsCardVariant, NewsCardVariantProps } from "./news-list.variants";

type NewsCardProps = ModelsNewsCard & NewsCardVariantProps;

export function NewsCard(props: NewsCardProps) {
  const {
    title,
    categoryName,
    heroImageUrl,
    url,
    appearance = "vertical",
    highlight = false,
  } = props;
  const styles = newsCardVariant({ appearance, highlight });

  return (
    <NextLink href={url} title={title || ""} className={styles.link()}>
      <article className={styles.base()} data-component-name="news-card">
        <div className={styles.figureWrapper()}>
          <div className={styles.figure()}>
            <NextImage
              alt={title || ""}
              width={"1080"}
              height={"1080"}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              src={heroImageUrl}
            />
          </div>
        </div>

        <div className={styles.textWrapper()}>
          <Text
            tag="span"
            size="small"
            theme="dark"
            weight={"semibold"}
            className={styles.preTitle()}
          >
            {categoryName}
          </Text>
          <Text
            tag="h3"
            theme="dark"
            weight={"medium"}
            className={styles.title()}
          >
            {title}
          </Text>
        </div>
      </article>
    </NextLink>
  );
}

import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { IconItem } from "../icon-item";
import IconCardButton from "./icon-card-button";
import { useIconCard, type UseIconCardProps } from "./use-icon-card";

export type IconCardProps = UseIconCardProps;

function IconCard(props: IconCardProps) {
  const { sectionName, className, styles, theme, hasList, appearance, data } =
    useIconCard(props);
  const { icon, title, description, list, link } = data;

  const isMinimalistCard = appearance === "minimalist";

  if (!title && !description) return;

  return (
    <article
      className={styles.base({ className })}
      data-component-name="icon-card"
    >
      <header className={styles.header()}>
        {!!icon && <Vector className={styles.icon()} name={icon} />}
        {!!title && (
          <Text
            className={styles.title()}
            size="subtitle"
            tag="h3"
            theme={theme}
            weight="medium"
          >
            {title}
          </Text>
        )}
        {!!description && (
          <Text className={styles.description()} theme={theme} weight="medium">
            {description}
          </Text>
        )}
        {hasList && (
          <ul className={styles.list()}>
            {list.map((item) => (
              <li key={item.id}>
                <IconItem theme={theme} data={item} />
              </li>
            ))}
          </ul>
        )}
      </header>
      {link ? (
        <footer className={styles.footer()}>
          <IconCardButton
            link={link}
            sectionName={sectionName}
            title={title}
            color={isMinimalistCard ? "blueLight" : "blue"}
          />
        </footer>
      ) : null}
    </article>
  );
}

export default IconCard;

"use client";

import { Button, type ButtonProps } from "../../ui/button";
import { Modal } from "../../util/modal";
import { useVideoModal } from "./use-video-modal";
import YouTube from "react-youtube";

interface VideoModalProps {
  videoURL: string;
  videoButtonLabel?: string | null;
  buttonStartIcon?: ButtonProps["startIcon"];
}

export default function VideoModal(props: VideoModalProps) {
  const {
    modalActive,
    handleShowModal,
    handleCloseModal,
    videoURL,
    videoId,
    isYoutubeVideo,
    buttonStartIcon,
    videoButtonLabel,
  } = useVideoModal(props);

  const VideoComponent = () => (
    <video className="aspect-w-16 aspect-h-9" controls>
      <source src={videoURL} type="video/mp4" />
    </video>
  );

  const Video = () => {
    return isYoutubeVideo ? (
      <YouTube
        className="aspect-w-16 aspect-h-9"
        iframeClassName="rounded-xl"
        onEnd={() => {
          handleCloseModal();
        }}
        opts={{
          playerVars: {
            autoplay: 1,
            color: "white",
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
          },
        }}
        videoId={videoId}
      />
    ) : (
      <VideoComponent />
    );
  };

  return (
    <>
      <Button
        appearance="solid"
        size="md"
        color="blue"
        onClick={handleShowModal}
        startIcon={buttonStartIcon ?? null}
      >
        {videoButtonLabel}
      </Button>

      <Modal
        active={modalActive}
        handleClose={handleCloseModal}
        className="bg-black"
      >
        <div className="aspect-w-16 aspect-h-9">
          <div className="p-4 bg-black rounded-3xl">
            <Video />
          </div>
        </div>
      </Modal>
    </>
  );
}

"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { IconCard } from "../../common/icon-card";
import type { SectionsCardContentCarousel, Theme } from "../../../types";
import { SwiperNav } from "../../util/swiper-nav";

export interface CardContentCarouselSwiperProps {
  className?: string;
  cardTheme: Theme;
  navTheme: Theme;
  navClassName?: string;
  sectionName: string;
  data: SectionsCardContentCarousel;
}

function CardContentCarouselSwiper({
  className,
  cardTheme,
  navTheme,
  navClassName,
  sectionName,
  data,
}: CardContentCarouselSwiperProps) {
  const { id, cardAppearance, cards } = data;

  const swiperId = `${sectionName}-${id}`;
  const isCardMinimalist = cardAppearance === "minimalist";

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        520: {
          slidesPerView: 2,
          spaceBetween: isCardMinimalist ? 18 : 20,
        },
        993: {
          slidesPerView: 3,
          spaceBetween: isCardMinimalist ? 18 : 40,
        },
      }}
      className={className}
      modules={[Navigation]}
      navigation={{
        prevEl: `.prev-carousel-${swiperId}`,
        nextEl: `.next-carousel-${swiperId}`,
      }}
      simulateTouch={false}
      slidesPerView={3}
      spaceBetween={40}
    >
      {cards.map((card) => (
        <SwiperSlide className="!h-auto" key={`icon-card-${card.id}`}>
          <IconCard
            appearance={cardAppearance}
            data={card}
            key={`card-content-${card.id}`}
            sectionName={sectionName}
            theme={cardTheme}
          />
        </SwiperSlide>
      ))}
      <SwiperNav className={navClassName} id={swiperId} theme={navTheme} />
    </Swiper>
  );
}

export default CardContentCarouselSwiper;

import { tv, type VariantProps } from "../../../util/tv";

export const cardContentCarouselVariant = tv({
  slots: {
    base: ["py-12", "overflow-hidden"],
    wrapper: ["container-fluid"],
    heading: ["flex flex-col"],
    preTitle: ["mb-3"],
    title: ["max-w-2xl"],
    description: ["mt-2 max-w-3xl"],
    content: ["mt-8 lg:mt-12"],
    carousel: ["w-full", "!overflow-visible", "flex-wrap items-stretch"],
    nav: [
      "hidden lg:flex rtl:lg:flex-row-reverse",
      "absolute -top-24 ltr:right-0 rtl:left-0",
    ],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-black"],
        preTitle: "text-blue-400",
        description: "text-gray-60",
      },
      light: {
        base: ["bg-gray-1"],
        preTitle: "text-blue-100",
        description: "text-gray-60",
      },
      gray: {
        base: ["bg-gray-900"],
        preTitle: ["text-blue-400"],
        description: ["text-gray-60"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type CardContentCarouselVariantProps = VariantProps<
  typeof cardContentCarouselVariant
>;

export type CardContentCarouselReturnVariantProps = ReturnType<
  typeof cardContentCarouselVariant
>;

"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import type { SectionsModulesRelated } from "../../../types";
import { SwiperNav } from "../../util/swiper-nav";
import { ModuleCard } from "../module-card";

export interface RelatedListSwiperProps {
  className?: string;
  navClassName?: string;
  sectionName: string;
  workflowModules?: boolean;
  data: SectionsModulesRelated;
}

function RelatedListSwiper({
  className,
  navClassName,
  sectionName,
  workflowModules,
  data,
}: RelatedListSwiperProps) {
  const { id, theme, cards } = data;
  const swiperId = `${sectionName}-${id}`;

  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: workflowModules ? 1 : 2,
          },
          768: {
            slidesPerView: workflowModules ? 2 : 3,
          },
          1024: {
            slidesPerView: workflowModules ? 3 : 4,
          },
        }}
        className={className}
        modules={[Navigation]}
        navigation={{
          prevEl: `.prev-carousel-${swiperId}`,
          nextEl: `.next-carousel-${swiperId}`,
        }}
        simulateTouch={false}
        slidesPerView={4}
        spaceBetween={24}
      >
        {cards.map((card) => (
          <SwiperSlide className="!h-auto" key={`module-card-${card.id}`}>
            <ModuleCard data={card} sectionName={sectionName} theme={theme} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperNav className={navClassName} id={swiperId} theme={theme} />
    </>
  );
}

export default RelatedListSwiper;

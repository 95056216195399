import { useState } from "react";
import { type ButtonProps } from "../../ui/button";
import { extractYouTubeId } from "../../../util/youtube";
import { dispatchGoogleEvent } from "../../../util/google";
import { EventAction } from "../../../config/events";

interface VideoModalProps {
  videoURL: string;
  videoButtonLabel?: string | null;
  buttonStartIcon?: ButtonProps["startIcon"];
}

export const useVideoModal = (props: VideoModalProps) => {
  const { videoURL, buttonStartIcon, videoButtonLabel } = props;
  const [modalActive, setModalActive] = useState(false);
  const isYoutubeVideo = !!videoURL && videoURL.includes("youtube");
  const videoId = extractYouTubeId(videoURL);

  const handleShowModal = () => {
    dispatchGoogleEvent(EventAction.WebsitePlay, `youtube_${videoId}`);
    setModalActive(true);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  return {
    modalActive,
    handleShowModal,
    handleCloseModal,
    videoURL,
    videoId,
    isYoutubeVideo,
    buttonStartIcon,
    videoButtonLabel,
  };
};

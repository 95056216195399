"use client";

import type { LinksLink } from "../../../types";
import { Button, type ButtonProps } from "../../ui/button";

export interface IconCardProps {
  sectionName?: string;
  title: string;
  link: LinksLink;
  color?: ButtonProps["color"];
}

function IconCardButton({
  sectionName,
  title,
  link,
  color = "blue",
}: IconCardProps) {
  return (
    <Button
      appearance="link"
      color={color}
      href={link.url}
      onClick={link.onClick}
      sectionName={`${sectionName}_${title}`}
      startIcon={link.startIcon}
      endIcon={link.endIcon}
      title={link.text}
    >
      {link.text}
    </Button>
  );
}

export default IconCardButton;

"use client";

import useInfiniteScroll from "../../../hooks/use-infinite-scroll";
import type { ModelsNewsCard, Theme } from "../../../types";
import { NewsCard } from "./news-card";
import { Button } from "../../ui/button";
import { newsListGridVariant } from "./news-list.variants";

export interface NewsListGridProps {
  theme: Theme;
  moreButtonLabel: string;
  sectionName: string;
  cards: ModelsNewsCard[];
}

function NewsListGrid({ cards }: NewsListGridProps) {
  const styles = newsListGridVariant();
  const items = useInfiniteScroll<ModelsNewsCard>(cards, 6);

  const { loading, hasMoreItems, loadedItems, getMoreItems } = items;

  const [highlight, ...posts] = loadedItems;

  const SideCards = () => {
    const _sideCards = [];

    for (let index = 1; index < 5; index++) {
      const card = cards[index];

      if (!card) return;

      _sideCards.push(
        <NewsCard
          {...card}
          appearance="horizontal"
          key={`news-card-${card.id}`}
        />
      );
    }

    return _sideCards;
  };

  return (
    <div className={styles.base()}>
      <div className={styles.content()}>
        <div className={styles.left()}>
          {highlight ? <NewsCard {...highlight} highlight={true} /> : null}
        </div>
        <div className={styles.right()}>
          <SideCards />
        </div>
      </div>
    </div>
  );
}

export default NewsListGrid;
